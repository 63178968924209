import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useMutation, gql } from '@apollo/client';
import { UPDATE_STATUS } from '../../Graphql/Mutations';
import { AuthContext } from '../../context/auth';

import './TermsAndConditions.css';

/////////////////////////////////////////////////////////////

const style = {
  width: 600,
  boxShadow: 24,
  borderRadius: 6,
  p: 4,
};

const TermsAndConditions = (props) => {
  const context = useContext(AuthContext);
  const [agreement, setAgreement] = useState('InActive');
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const id = user.id;

  const [updateUserStatus, { loading }] = useMutation(UPDATE_STATUS);

  const onSubmit = async () => {
    if (id != '') {
      updateUserStatus({
        update(_, result) {
          console.log(result.data.updateUserStatus);
          context.login(result.data.updateUserStatus);
          navigate('/dashboard');
        },
        variables: {
          user: id,
          status: agreement,
        },
      });
    } else {
      alert('Please accept our terms and conditions');
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-8 col-md-12">
          <div className="row">
            <div className="col-6 col-md-12 col-sm-12 mb">
              <div className="summary-box">
                <Box sx={style}>
                  <h4 style={{ textAlign: 'center', marginBottom: 16 }}>
                    Our Terms and Conditions
                  </h4>

                  <List
                    sx={{
                      bgcolor: 'background.paper',
                    }}
                    className="disclainerContainer"
                  >
                    <h6 style={{ textAlign: 'center' }}>
                      AS an authorized representative of my organization/
                      company/ department/ municipality hereby agree and
                      authorise MDT institute to Undertake credential
                      verification on our behalf and understand that:
                    </h6>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <DownloadDoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText secondary="As the client I have obtained consent from the information holder/ data subject authorising the  processing of his/ or her personal information;" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <DownloadDoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText secondary="We undertake to submit the consent form duly signed by the information holder to MDT institute as and when required;" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <DownloadDoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText secondary="MDT Institute agrees to process personal information requested by the client within the agreed turnaround times;" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <DownloadDoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText secondary="MDT institute will only commence processing personal information requests once these terms and conditions have been accepted by the client;" />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <DownloadDoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText secondary="The client must fill and submit the personal information and type of verification/s required on the online portal or by email using the prescribed consent form to: Verifications@mdtinstitute.co.za;" />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <DownloadDoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText secondary="A request will be considered as submitted once it is submitted to the correct email address/ or correctly captured on the online portal and the relevant consent form has been received by MDT Institute;" />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <DownloadDoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText secondary="It is the client’s responsibility to ensure that correct verification type/ or service is requested by them to enable MDT institute to process information of such a request. No refund will be processed should the client choose incorrect verification services;" />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <DownloadDoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText secondary="There is no limit to the number of request to be submitted by the client;" />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <DownloadDoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText secondary="A record is defined as a one verification type per individual. i.e. If a person has obtained three (03) qualifications, each will constitute a record respectively;" />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <DownloadDoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText secondary="Where a record is not found within agreed turnaround times MDT institute will close the file as “Inconclusive” and the client is required to authorise MDT institute continue  with the investigation/ or information processing;" />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <DownloadDoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText secondary="The client agrees to pay MDT institute within 30 Days after the service has been rendered;" />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <DownloadDoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText secondary="MDT Institute reserves the right to discontinue verification services if payment is not received within 30 Days or agreed payment period." />
                    </ListItem>

                    <FormControlLabel
                      control={
                        <Checkbox onChange={() => setAgreement('Active')} />
                      }
                      label="Agree"
                    />
                    {agreement == 'Active' ? (
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={onSubmit}
                        style={{
                          marginTop: 10,
                          marginRight: 'auto',
                          marginBottom: 12,
                        }}
                      >
                        Continue
                      </Button>
                    ) : (
                      ''
                    )}
                  </List>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
