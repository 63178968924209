import { gql } from '@apollo/client';

export const GET_USER_VERIFICATIONS = gql`
  query getUserVerifications($userId: String!) {
    getUserVerifications(userId: $userId) {
      id
      verificationType
      firstName
      lastName
      idNumber
      maidenName
      status
      price
      paymentStatus
      requestReason
      organisationName
      saved
      reason
      fileName
      createdAt
    }
  }
`;

export const GET_CART = gql`
  query getCart($userId: String!) {
    getCart(userId: $userId) {
      id
      verificationType
      price
      createdAt
    }
  }
`;

export const GET_ORGANISATION_USERS = gql`
  query getOrganisationUsers($id: String!) {
    getOrganisationUsers(id: $id) {
      id
      verificationType
      firstName
      maidenName
      lastName
      idNumber
      status
      price
      paymentStatus
      organisationName
      saved
      requestReason
      fileName
      createdAt
    }
  }
`;
