import React, { useState } from 'react';
import {
  Container,
  Typography,
  Link,
  Divider,
  Box,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import styled from '@emotion/styled';
import LoginForm from '../../components/LoginForm';
import SignupForm from '../../components/SignupForm';
import { motion } from 'framer-motion';
import DashboardWrapper, {
  DashboardWrapperMain,
} from '../../components/dashboard-wrapper/DashboardWrapper';
import './Home.scss';

import logo from '../../assets/images/logo.png';
import verify from '../../assets/home/verify.png';
import home from '../../assets/home/home.png';

//////////////////////////////////
const RootStyle = styled('div')({
  background: 'rgb(249, 250, 251)',
  height: '100vh',
  display: 'grid',
  placeItems: 'center',
});

const HeadingStyle = styled(Box)({
  textAlign: 'center',
});

const ContentStyle = styled('div')({
  maxWidth: 480,
  padding: 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fff',
});

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const style = {
  position: 'absolute',
  top: '54%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Home = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const organisationBackground = '#1ac9a3';
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <RootStyle>
              <Container maxWidth="sm">
                <ContentStyle>
                  <Typography
                    sx={{ color: 'text.secondary', mb: 5 }}
                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                  >
                    Enter your details below
                  </Typography>

                  <Divider sx={{ my: 3 }} component={motion.div} {...fadeInUp}>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary' }}
                    >
                      Registration
                    </Typography>
                  </Divider>

                  <SignupForm back={organisationBackground} />

                  <Typography
                    component={motion.p}
                    {...fadeInUp}
                    variant="body2"
                    align="center"
                    sx={{ mt: 3 }}
                  >
                    Already have an account?{' '}
                    <Link
                      variant="subtitle2"
                      style={{ cursor: 'pointer' }}
                      onClick={handleClose}
                    >
                      Sign in
                    </Link>
                  </Typography>
                </ContentStyle>
              </Container>
            </RootStyle>
          </Box>
        </Fade>
      </Modal>
      <div className="main">
        <div className="main__content">
          <div className="topnav">
            <div className="user-info">
              <img src={logo} alt="" />
            </div>
            <div className="sidebar-toggle"></div>
          </div>
          <DashboardWrapper>
            <DashboardWrapperMain>
              <div className="row">
                <div className="col-8 col-md-12">
                  <div className="row">
                    <div className="col-12 col-md-12 col-sm-12 mb">
                      <RootStyle
                        style={{ height: 200, backgroundColor: '#0e1536' }}
                      >
                        <Container maxWidth="lg" style={{ padding: 10 }}>
                          <div className="titleDiv">
                            <img src={home} alt="" />
                            <h4
                              style={{
                                textAlign: 'center',
                                justifyContent: 'center',
                                fontWeight: 'bold',
                                marginTop: 12,
                              }}
                            >
                              Integrity Management
                            </h4>
                          </div>

                          <h4
                            style={{
                              textAlign: 'center',
                              justifyContent: 'center',
                              marginTop: 10,
                              color: '#939393',
                            }}
                          >
                            Welcome to MDT Institute Verification portal - Data
                            Integrity is our number one priority.
                          </h4>
                        </Container>
                      </RootStyle>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-6 col-sm-12 mb">
                      <RootStyle>
                        <Container maxWidth="sm" style={{ padding: 10 }}>
                          <img className="homepageBoard" src={verify} alt="" />
                        </Container>
                      </RootStyle>
                    </div>
                    <div className="col-6 col-md-6 col-sm-12 mb">
                      <RootStyle>
                        <Container maxWidth="sm">
                          <ContentStyle
                            style={{
                              borderColor: '#82140a',
                              borderStyle: 'solid',
                              borderWidth: 8,
                            }}
                          >
                            <Divider
                              sx={{ my: 3 }}
                              component={motion.div}
                              {...fadeInUp}
                            >
                              <Typography
                                variant="body2"
                                sx={{ color: 'text.secondary' }}
                                style={{ fontWeight: 'bold', fontSize: 18 }}
                              >
                                LOGIN
                              </Typography>
                            </Divider>

                            <LoginForm back={organisationBackground} />

                            <Typography
                              component={motion.p}
                              {...fadeInUp}
                              variant="body2"
                              align="center"
                              sx={{ mt: 3 }}
                            >
                              Don’t have an account?{' '}
                              <Link
                                variant="subtitle2"
                                onClick={handleOpen}
                                style={{ color: '#0e1536', cursor: 'pointer' }}
                              >
                                Sign up
                              </Link>
                            </Typography>
                          </ContentStyle>
                        </Container>
                      </RootStyle>
                    </div>
                  </div>
                </div>
              </div>
            </DashboardWrapperMain>
          </DashboardWrapper>
        </div>
      </div>
    </>
  );
};

export default Home;
