import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { Stack, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useMutation, gql } from '@apollo/client';
import { UPLOAD_ID } from '../../Graphql/Mutations';
import { AuthContext } from '../../context/auth';

import './IdUpload.css';

/////////////////////////////////////////////////////////////
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const style = {
  width: 600,
  boxShadow: 24,
  borderRadius: 6,
  p: 4,
};

const IdUpload = (props) => {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const [description, setDescription] = useState('');
  const [fileSelect, setFileSelect] = useState('');

  const [message, setMessage] = useState('');

  const { user } = useContext(AuthContext);
  const id = user.id;

  const [file, setFile] = useState('');

  const SUPABASE_KEY =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxhaG9raXlxbnZjYWdwY2R6dmN4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDQ2MDI5OTEsImV4cCI6MTk2MDE3ODk5MX0.-OBafJvE4CB6IGMNV3qesbrWna2oMoPPhb1Cxqq65Ao';
  const SUPABASE_URL = 'https://lahokiyqnvcagpcdzvcx.supabase.co';

  const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

  const [uploadId, { loading }] = useMutation(UPLOAD_ID);

  const Input = styled('input')({
    display: 'none',
  });

  const onSubmit = async () => {
    var fileName = '';

    if (file != '') {
      let arr = file.name.split('.');
      let extension = arr.pop();

      fileName =
        'id-passport_' +
        Math.floor(Math.random() * 10000000000 + 2) +
        '.' +
        extension;
      //console.log(file);
      const { data, error } = await supabase.storage
        .from('motorluv-bucket/mdt/mdt-ids')
        .upload(fileName, file);
      if (error) {
        console.log(error);
      } else {
        // console.log(data);
      }
    } else {
      fileName = '';
    }

    if (id != '' && fileName != '') {
      uploadId({
        update(_, result) {
          console.log(result.data.uploadId);
          context.login(result.data.uploadId);
          navigate('/dashboard');
        },
        variables: {
          user: id,
          fileName: fileName,
        },
      });
    } else {
      alert('Please fill in all fields!');
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-8 col-md-12">
          <div className="row">
            <div className="col-6 col-md-12 col-sm-12 mb">
              <div className="summary-box">
                <Box sx={style}>
                  <h4 style={{ textAlign: 'center', marginBottom: 16 }}>
                    Upload Your ID or Passport
                  </h4>

                  <Stack
                    component={motion.div}
                    initial={{ opacity: 0, y: 60 }}
                    animate={animate}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                  >
                    <label
                      htmlFor="contained-button-file"
                      style={{ marginTop: 12, marginBottom: 12 }}
                    >
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                          setFileSelect('File Selected');
                        }}
                      />
                      {fileSelect ? (
                        <Button
                          variant="outlined"
                          component="span"
                          style={{ color: 'green' }}
                        >
                          ID/Passport Selected
                        </Button>
                      ) : (
                        <Button variant="outlined" component="span">
                          Select ID/Passport
                        </Button>
                      )}
                    </label>
                  </Stack>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IdUpload;
