import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser(
    $firstName: String!
    $maidenName: String!
    $lastName: String!
    $idNumber: String!
    $email: String!
    $password: String!
    $type: String!
    $status: String!
    $registrationNumber: String
    $fileName: String
    $organisationName: String
  ) {
    createUser(
      registerInput: {
        firstName: $firstName
        maidenName: $maidenName
        lastName: $lastName
        idNumber: $idNumber
        email: $email
        password: $password
        type: $type
        status: $status
        registrationNumber: $registrationNumber
        fileName: $fileName
        organisationName: $organisationName
      }
    ) {
      id
      firstName
      lastName
      maidenName
      idNumber
      email
      password
      type
      status
      token
      fileName
      createdAt
    }
  }
`;

export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      firstName
      maidenName
      lastName
      idNumber
      organisationName
      email
      password
      type
      status
      createdAt
      token
    }
  }
`;

export const UPLOAD_ID = gql`
  mutation uploadId($user: String!, $fileName: String!) {
    uploadId(id: $user, fileName: $fileName) {
      id
      firstName
      maidenName
      lastName
      idNumber
      organisationName
      email
      password
      type
      status
      createdAt
      token
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation updateUserStatus($user: String!, $status: String!) {
    updateUserStatus(id: $user, status: $status) {
      id
      firstName
      maidenName
      lastName
      idNumber
      organisationName
      email
      password
      type
      status
      createdAt
      token
    }
  }
`;

export const CREATE_VERIFICATION = gql`
  mutation createVerification(
    $verificationType: String!
    $user: String!
    $fileName: String
    $firstName: String!
    $lastName: String!
    $idNumber: String!
    $maidenName: String!
    $organisationName: String
    $qualification: String
    $institution: String
    $year: String
    $requestReason: String!
    $licenseNumber: String
    $code: String
    $price: Float!
    $prdp: String
    $currentEmployer: String
    $refereeName: String
    $refereeNumber: String
    $saved: Boolean!
  ) {
    createVerification(
      verificationType: $verificationType
      user: $user
      fileName: $fileName
      firstName: $firstName
      lastName: $lastName
      idNumber: $idNumber
      maidenName: $maidenName
      organisationName: $organisationName
      qualification: $qualification
      institution: $institution
      year: $year
      requestReason: $requestReason
      licenseNumber: $licenseNumber
      code: $code
      price: $price
      prdp: $prdp
      currentEmployer: $currentEmployer
      refereeName: $refereeName
      refereeNumber: $refereeNumber
      saved: $saved
    ) {
      id
      organisationName
    }
  }
`;

export const CREATE_PROOF_OF_PAYMENT = gql`
  mutation createProofOfPayment(
    $user: String!
    $description: String!
    $fileName: String!
  ) {
    createProofOfPayment(
      user: $user
      description: $description
      fileName: $fileName
    ) {
      id
    }
  }
`;

export const DELETE_CART = gql`
  mutation deleteCart($ids: [String!]) {
    deleteCart(ids: $ids)
  }
`;

export const DELETE_CART_ITEM = gql`
  mutation deleteCartItem($verificationId: String!) {
    deleteCartItem(verificationId: $verificationId)
  }
`;

export const SAVE_CART = gql`
  mutation saveCart($ids: [String!]) {
    saveCart(ids: $ids)
  }
`;

export const UPDATE_TOKEN = gql`
  mutation updateToken($id: String!, $token: String!) {
    updateToken(id: $id, token: $token)
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($id: String!, $password: String!) {
    updatePassword(id: $id, password: $password)
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      id
      firstName
      maidenName
      lastName
      idNumber
      organisationName
      email
      password
      type
      status
      createdAt
      token
    }
  }
`;

export const GET_CLIENT_VERIFICATIONS = gql`
  mutation getClientVerifications($idNumber: String!) {
    getClientVerifications(idNumber: $idNumber) {
      id
      verificationType
      firstName
      lastName
      maidenName
      idNumber
      status
      price
      paymentStatus
      requestReason
      organisationName
      saved
      reason
      fileName
      createdAt
    }
  }
`;
