import React, { useState, useContext } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import {
  Stack,
  Box,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useMutation, gql } from '@apollo/client';
import { CREATE_PROOF_OF_PAYMENT } from '../../Graphql/Mutations';
import { AuthContext } from '../../context/auth';

import './Billing.css';

/////////////////////////////////////////////////////////////
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const style = {
  width: 600,
  boxShadow: 24,
  borderRadius: 6,
  p: 4,
};

const Billing = (props) => {
  const [description, setDescription] = useState('');
  const [fileSelect, setFileSelect] = useState('');

  const [receiver, setReceiver] = useState(20575137);
  const [returnUrl, setReturnUrl] = useState(
    'https://www.verifymdtinstitute.co.za/dashboard/billing'
  );
  const [cancelUrl, setCancelUrl] = useState(
    'https://www.verifymdtinstitute.co.za/dashboard/billing'
  );
  const [amount, setAmount] = useState();
  const [quantity, setQuantiry] = useState();
  const [itemName, setItemName] = useState('');
  const [message, setMessage] = useState('');

  const { user } = useContext(AuthContext);
  const id = user.id;

  const [file, setFile] = useState('');

  const SUPABASE_KEY =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxhaG9raXlxbnZjYWdwY2R6dmN4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDQ2MDI5OTEsImV4cCI6MTk2MDE3ODk5MX0.-OBafJvE4CB6IGMNV3qesbrWna2oMoPPhb1Cxqq65Ao';
  const SUPABASE_URL = 'https://lahokiyqnvcagpcdzvcx.supabase.co';

  const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

  const [createProofOfPayment, { loading }] = useMutation(
    CREATE_PROOF_OF_PAYMENT
  );

  const Input = styled('input')({
    display: 'none',
  });

  const onSubmit = async () => {
    var fileName = '';

    if (file != '') {
      let arr = file.name.split('.');
      let extension = arr.pop();

      fileName =
        'proof_of_payment_' +
        Math.floor(Math.random() * 10000000000 + 2) +
        '.' +
        extension;
      //console.log(file);
      const { data, error } = await supabase.storage
        .from('motorluv-bucket/proof-of-payment')
        .upload(fileName, file);
      if (error) {
        console.log(error);
      } else {
        // console.log(data);
      }
    } else {
      fileName = '';
    }

    if (id != null && description != '' && fileName != '') {
      createProofOfPayment({
        update(_, result) {
          alert('Success!');
        },
        variables: {
          user: id,
          description: description,
          fileName: fileName,
        },
      });
    } else {
      alert('Fill in all fields!');
    }
  };

  let handleSubmit = async (e) => {
    e.preventDefault();

    if (itemName != '' && amount != null && quantity != null) {
      window.location.replace(
        'https://www.payfast.co.za/eng/process?merchant_key=odu07ogs1w10s&return_url=https://www.verifymdtinstitute.co.za/dashboard/billing&cancel_url=https://www.verifymdtinstitute.co.za/dashboard/billing&item_name=' +
          itemName +
          '&amount=' +
          amount +
          '&quantity=' +
          quantity +
          '&merchant_id=20575137'
      );
    } else {
      alert('Please fill in all fields!');
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-8 col-md-12">
          <div className="row">
            <div className="col-6 col-md-12 col-sm-12 mb">
              <div className="summary-box">
                <Box sx={style}>
                  <h4 style={{ textAlign: 'center', marginBottom: 16 }}>
                    Proof Of Payment
                  </h4>
                  <TextareaAutosize
                    aria-label="empty textarea"
                    minRows={3}
                    placeholder="Description"
                    style={{
                      width: 200,
                      border: 'none',
                      padding: 6,
                      borderRadius: 6,
                    }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <Stack
                    component={motion.div}
                    initial={{ opacity: 0, y: 60 }}
                    animate={animate}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                  >
                    <label
                      htmlFor="contained-button-file"
                      style={{ marginTop: 12, marginBottom: 12 }}
                    >
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                          setFileSelect('File Selected');
                        }}
                      />
                      {fileSelect ? (
                        <Button
                          variant="outlined"
                          component="span"
                          style={{ color: 'green' }}
                        >
                          File Selected
                        </Button>
                      ) : (
                        <Button variant="outlined" component="span">
                          SelectDocument
                        </Button>
                      )}
                    </label>
                  </Stack>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                  >
                    Submit
                  </LoadingButton>

                  <h4
                    style={{
                      textAlign: 'center',
                      marginTop: 16,
                      borderTopWidth: 1,
                      borderTopColor: 'grey',
                    }}
                  >
                    PayFast
                  </h4>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      variant="standard"
                      fullWidth
                      type="number"
                      label="Amount"
                      onChange={(e) => setAmount(e.target.value)}
                      className="user-details-input"
                    />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Reference"
                      onChange={(e) => setItemName(e.target.value)}
                      className="user-details-input"
                    />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="number"
                      label="Quantity"
                      onChange={(e) => setQuantiry(e.target.value)}
                      className="user-details-input"
                    />

                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      style={{ marginTop: 12, backgroundColor: '#eb5534' }}
                    >
                      Pay Now
                    </LoadingButton>
                    <div className="message">
                      {message ? <p>{message}</p> : null}
                    </div>
                  </form>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Billing;
