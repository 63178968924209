import React, { useState } from 'react';
import './summary-box.scss';
import Box from '../box/Box';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { useMutation, useQuery, gql } from '@apollo/client';
import Button from '@mui/material/Button';
import { DELETE_CART_ITEM } from '../../Graphql/Mutations';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CartSummaryBox = ({ item }) => {
  let randFormat = Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
  });

  const [removeCartItem] = useMutation(DELETE_CART_ITEM, {
    update(proxy) {
      alert('Request canceled!');
    },
    variables: {
      verificationId: item.id,
    },
  });

  return (
    <Box>
      <div className="summary-box">
        <div className="summary-box__info">
          <div className="summary-box__info__title">
            <div>{item.verificationType}</div>
            <span>{item.createdAt}</span>
            <p style={{ color: '#0362fc' }}>{randFormat.format(item.price)}</p>
          </div>
        </div>
        <div className="summary-box__chart">
          <Button
            fullWidth
            variant="outlined"
            color="warning"
            onClick={removeCartItem}
            style={{
              marginTop: 10,
              marginRight: 'auto',
              marginBottom: 12,
            }}
          >
            Remove
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default CartSummaryBox;

export const SummaryBoxSpecial = ({ item }) => {
  const chartOptions = {
    responsive: true,
    scales: {
      xAxis: {
        display: false,
      },
      yAxis: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const chartData = {
    labels: item.chartData.labels,
    datasets: [
      {
        label: 'Revenue',
        data: item.chartData.data,
        borderColor: '#fff',
        tension: 0.5,
      },
    ],
  };
  return (
    <Box purple fullheight>
      <div className="summary-box-special">
        <div className="summary-box-special__title">{item.title}</div>
        <div className="summary-box-special__value">{item.value}</div>
        <div className="summary-box-special__chart">
          <Line options={chartOptions} data={chartData} width={`250px`} />
        </div>
      </div>
    </Box>
  );
};
