import React, { useContext, useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import IconButton from '@mui/material/IconButton';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { GET_USER_VERIFICATIONS } from '../Graphql/Queries';
import { GET_CLIENT_VERIFICATIONS } from '../Graphql/Mutations';
import { GET_ORGANISATION_USERS } from '../Graphql/Queries';
import DashboardWrapper, {
  DashboardWrapperMain,
  DashboardWrapperRight,
} from '../components/dashboard-wrapper/DashboardWrapper';
import SummaryBox, {
  SummaryBoxSpecial,
} from '../components/summary-box/SummaryBox';
import { AuthContext } from '../context/auth';
import { data } from '../constants';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const { user } = useContext(AuthContext);

  const [userIdNumber, setIdNumber] = useState('');

  const { loading: userLoading, data: userData } = useQuery(
    GET_USER_VERIFICATIONS,
    {
      pollInterval: 4000,
      variables: {
        userId: user.id,
      },
    }
  );

  const [getClientData, { loading: load }] = useMutation(
    GET_CLIENT_VERIFICATIONS
  );

  const { loading: usersLoading, data: users } = useQuery(
    GET_ORGANISATION_USERS,
    {
      pollInterval: 4000,
      variables: {
        id: user.id,
      },
    }
  );

  var randFormat = Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
  });

  const columnsIndividual = [
    {
      field: 'id',
      headerName: 'Reference',
      width: 160,
      renderCell: (params) => <p>{user.id.substring(3, 10)}</p>,
    },
    { field: 'verificationType', headerName: 'Type', width: 220 },
    { field: 'status', headerName: 'Status', width: 200 },
    { field: 'reason', headerName: 'Reason', width: 240 },
    { field: 'requestReason', headerName: 'Request Reason', width: 240 },
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 220,
    },
  ];

  const columnsOrganisation = [
    {
      field: 'id',
      headerName: 'Reference',
      width: 160,
      renderCell: (params) => <p>{params.row.id.substring(3, 10)}</p>,
    },
    { field: 'firstName', headerName: 'First Name', width: 220 },
    { field: 'lastName', headerName: 'Last Name', width: 200 },
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 220,
    },
    {
      field: 'action',
      headerName: ' ',
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={(event) => handlePdfDownload(params.row.idNumber)}>
          <ArrowCircleDownIcon color="success" />
        </IconButton>
      ),
    },
  ];

  var rowsOrganisation = [];
  var total = 0;
  if (userData) {
    for (var i = 0; i < userData.getUserVerifications.length; i++) {
      if (
        userData.getUserVerifications[i].paymentStatus == 'Outstanding' &&
        userData.getUserVerifications[i].saved
      ) {
        total += userData.getUserVerifications[i].price;
      }

      rowsOrganisation.push({
        id: userData.getUserVerifications[i].id,
        firstName: userData.getUserVerifications[i].firstName,
        verificationType: userData.getUserVerifications[i].verificationType,
        lastName: userData.getUserVerifications[i].lastName,
        createdAt: userData.getUserVerifications[i].createdAt,
        idNumber: userData.getUserVerifications[i].idNumber,
        reason: userData.getUserVerifications[i].reason,
        verificationReason: userData.getUserVerifications[i].verificationReason,
        status: userData.getUserVerifications[i].status,
        requestReason: userData.getUserVerifications[i].requestReason,
      });
    }
  }

  var rowsIndividualPDF = [];
  var total = 0;

  rowsIndividualPDF.push({
    id: user.id.substring(3, 10),
    organisation: user.organisationName,
    firstName: user.firstName,
    maidenName: user.maidenName,
    lastName: user.lastName,
    idNumber: user.idNumber,
    createdAt: user.createdAt.substring(0, 10),
    verificationType: user.verificationType,
    reason: user.reason,
    requestReason: user.requestReason,
    status: user.status,
  });

  var rowsIndividual = [];
  var total = 0;
  if (userData) {
    for (var i = 0; i < userData.getUserVerifications.length; i++) {
      if (
        userData.getUserVerifications[i].paymentStatus == 'Outstanding' &&
        userData.getUserVerifications[i].saved
      ) {
        total += userData.getUserVerifications[i].price;
      }

      rowsIndividual.push({
        id: userData.getUserVerifications[i].id.substring(3, 10),
        ref: user.id.substring(3, 10),
        firstName: userData.getUserVerifications[i].firstName,
        lastName: userData.getUserVerifications[i].lastName,
        maidenName: userData.getUserVerifications[i].maidenName,
        idNumber: userData.getUserVerifications[i].idNumber,
        verificationType: userData.getUserVerifications[i].verificationType,
        createdAt: userData.getUserVerifications[i].createdAt.substring(0, 10),
        status: userData.getUserVerifications[i].status,
        reason: userData.getUserVerifications[i].reason,
        requestReason: userData.getUserVerifications[i].requestReason,
      });
    }
  }

  //console.log(rowsIndividualPDF);
  const downloadPdf = () => {
    let left = 15;
    let top = 8;
    const imgWidth = 180;
    const imgHeight = 70;

    const doc = new jsPDF();
    var img = new Image();
    img.src = require('../assets/images/letterhead.png');
    doc.addImage(img, 'png', left, top, imgWidth, imgHeight);
    doc.setFont(undefined, 'bold');
    doc.text('Verification Report', 80, 90);

    doc.setFontSize(10);
    doc.setFont(undefined, 'bold');
    doc
      .text('PERSONAL INFORMATION OF CANDIDATE/ DATA SUBJECT', 15, 124)
      .setFont(undefined, 'bold');
    doc.autoTable({
      startY: 129,
      startX: 10,
      headStyles: { fillColor: [143, 34, 13] },
      columns: [
        { dataKey: 'firstName', header: 'Name' },
        { dataKey: 'maidenName', header: 'Maiden Name' },
        { dataKey: 'lastName', header: 'Surname' },
        { dataKey: 'idNumber', header: 'Id/Passport' },
        {
          dataKey: 'createdAt',
          header: 'Date',
        },
      ],
      body: rowsIndividualPDF,
    });

    doc.setFontSize(10);
    doc.text('VERIFICATION RESULTS', 15, 154).setFont(undefined, 'bold');
    doc.autoTable({
      startY: 157,
      startX: 15,
      headStyles: { fillColor: [143, 34, 13] },
      columns: [
        { dataKey: 'verificationType', header: 'Type' },
        { dataKey: 'status', header: 'Status' },
        { dataKey: 'reason', header: 'Reason' },
        { dataKey: 'requestReason', header: 'Request Reason' },
        {
          dataKey: 'createdAt',
          header: 'Date',
        },
      ],
      body: rowsIndividual,
    });
    doc.save('report.pdf');
  };

  async function handlePdfDownload(userIdNumber) {
    getClientData({
      update(_, result) {
        const clientData = result.data.getClientVerifications;

        var rowsOrganisationPDF = [];

        var clientPDF = [];
        if (user) {
          clientPDF.push({
            id: user.id.substring(3, 10),
            organisation: user.organisationName,
            name: user.firstName + ' ' + user.lastName,
            createdAt: user.createdAt.substring(0, 10),
          });
        }

        for (var i = 0; i < clientData.length; i++) {
          rowsOrganisationPDF.push({
            id: clientData[i].id.substring(3, 10),
            organisation: user.organisationName,
            firstName: clientData[i].firstName,
            maidenName: clientData[i].maidenName,
            lastName: clientData[i].lastName,
            idNumber: clientData[i].idNumber,
            createdAt: clientData[i].createdAt.substring(0, 10),
            verificationType: clientData[i].verificationType,
            reason: clientData[i].reason,
            verificationReason: clientData[i].verificationReason,
            status: clientData[i].status,
            requestReason: clientData[i].requestReason,
          });
        }

        var candidatePDF = [];
        var total = 0;
        if (users) {
          for (var i = 0; i < 1; i++) {
            if (
              users.getOrganisationUsers[i].paymentStatus == 'Outstanding' &&
              users.getOrganisationUsers[i].saved
            ) {
              total += users.getOrganisationUsers[i].price;
            }
            candidatePDF.push({
              id: clientData[i].id.substring(3, 10),
              organisation: user.organisationName,
              firstName: clientData[i].firstName,
              maidenName: clientData[i].maidenName,
              lastName: clientData[i].lastName,
              idNumber: clientData[i].idNumber,
              createdAt: clientData[i].createdAt.substring(0, 10),
              verificationType: clientData[i].verificationType,
              reason: clientData[i].reason,
              verificationReason: clientData[i].verificationReason,
              status: clientData[i].status,
              requestReason: clientData[i].requestReason,
            });
          }
        }
        let left = 15;
        let top = 8;
        const imgWidth = 180;
        const imgHeight = 70;

        const doc = new jsPDF();
        var img = new Image();
        img.src = require('../assets/images/letterhead.png');
        doc.addImage(img, 'png', left, top, imgWidth, imgHeight);
        doc.setFont(undefined, 'bold');
        doc.text('Verification Report', 80, 94).setFont(undefined, 'bold');

        doc.setFontSize(10);
        doc.setFont(undefined, 'normal');
        doc.text(
          'MDT Institute takes full responsibility for the contents of this report. The decision to appoint or not to appoint rest with the client.',
          10,
          115
        );

        doc.setFontSize(10);
        doc
          .text(
            'Data subject must be informed of the contents of this report.',
            10,
            121
          )
          .setFont(undefined, 'bold');

        doc.setFontSize(10);
        doc.text('CLIENT INFORMATION ', 15, 129);
        doc.autoTable({
          startY: 132,
          startX: 10,
          headStyles: { fillColor: [143, 34, 13] },
          columns: [
            { dataKey: 'organisation', header: 'Cliet Name' },
            { dataKey: 'name', header: 'Requested By' },
            { dataKey: 'createdAt', header: 'Request Date' },
            { dataKey: 'id', header: 'Ref No' },
          ],
          body: clientPDF,
        });

        doc.setFontSize(10);
        doc
          .text('PERSONAL INFORMATION OF CANDIDATE/ DATA SUBJECT', 15, 156)
          .setFont(undefined, 'bold');
        doc.autoTable({
          startY: 159,
          startX: 10,
          headStyles: { fillColor: [143, 34, 13] },
          columns: [
            { dataKey: 'firstName', header: 'Name' },
            { dataKey: 'maidenName', header: 'Maiden Name' },
            { dataKey: 'lastName', header: 'Surname' },
            { dataKey: 'idNumber', header: 'Id/Passport' },
            {
              dataKey: 'createdAt',
              header: 'Date',
            },
          ],
          body: candidatePDF,
        });

        doc.setFontSize(10);
        doc.text('VERIFICATION RESULTS', 15, 180).setFont(undefined, 'bold');

        doc.autoTable({
          startY: 184,
          startX: 10,
          headStyles: { fillColor: [143, 34, 13] },
          columns: [
            { dataKey: 'verificationType', header: 'Type' },
            { dataKey: 'status', header: 'Status' },
            { dataKey: 'reason', header: 'Reason' },
            { dataKey: 'requestReason', header: 'Request Reason' },
            {
              dataKey: 'createdAt',
              header: 'Date',
            },
          ],
          body: rowsOrganisationPDF,
        });

        doc.save('report.pdf');
      },
      onError(err) {
        alert(err);
      },

      variables: {
        idNumber: userIdNumber,
      },
    });
  }

  return (
    <DashboardWrapper>
      <DashboardWrapperMain>
        <div className="row">
          <div className="col-8 col-md-12">
            <div className="row">
              {userLoading ? (
                <h1>Loading data..</h1>
              ) : (
                userData &&
                userData.getUserVerifications.map((item, index) => (
                  <div
                    key={`summary-${index}`}
                    className="col-6 col-md-6 col-sm-12 mb"
                  >
                    <SummaryBox item={item} />
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="col-4 hide-md">
            <SummaryBoxSpecial item={data.revenueSummary} />
          </div>
        </div>
      </DashboardWrapperMain>
      {user.type == 'Individual' ? (
        <DashboardWrapperRight>
          <div className="title mb">
            Report
            <div
              style={{
                float: 'right',
                margin: 12,
                color: '#bd204a',
                cursor: 'pointer',
              }}
            >
              {rowsIndividual.length > 0 ? (
                <DownloadForOfflineIcon
                  onClick={downloadPdf}
                  fontSize="large"
                />
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="mb">
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={rowsIndividual}
                columns={columnsIndividual}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
              />
              <p
                style={{ marginTop: 12, fontWeight: 'bold', color: '#ab021e' }}
              >
                Outstanding: {randFormat.format(total)}
              </p>
            </div>
          </div>
        </DashboardWrapperRight>
      ) : (
        <DashboardWrapperRight>
          <div className="title mb">
            Report
            <div
              style={{
                float: 'right',
                margin: 12,
                color: '#bd204a',
                cursor: 'pointer',
              }}
            ></div>
          </div>

          <div className="mb">
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={rowsOrganisation}
                columns={columnsOrganisation}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
              />
              <p
                style={{
                  marginTop: 12,
                  fontWeight: 'bold',
                  color: '#ab021e',
                }}
              >
                Outstanding: {randFormat.format(total)}
              </p>
            </div>
          </div>
        </DashboardWrapperRight>
      )}
    </DashboardWrapper>
  );
};

export default Dashboard;
