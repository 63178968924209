import React from 'react';
import './user-info.scss';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';

const UserInfo = ({ user }) => {
  return (
    <div className="user-info">
      <Avatar sx={{ bgcolor: deepOrange[500] }}>
        {user.firstName.charAt(0)}
      </Avatar>
      <div className="user-info__name">
        <span>{user.firstName}</span>
      </div>
    </div>
  );
};

export default UserInfo;
