import React, { useState, useContext } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import { useMutation } from '@apollo/react-hooks';
import { LOGIN_USER } from '../Graphql/Mutations';
import { FORGOT_PASSWORD } from '../Graphql/Mutations';
import { AuthContext } from '../context/auth';

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const LoginForm = (props) => {
  const context = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Provide a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const [loginUser, { loading }] = useMutation(LOGIN_USER, {
    update(_, result) {
      context.login(result.data.login);
      navigate('/dashboard');
      if (result.data.login.status == 'Active') {
        navigate('/dashboard');
      }
      if (
        result.data.login.type == 'Individual' &&
        result.data.login.status == 'inActive'
      ) {
        navigate('/dashboard/idUpload');
      }

      if (
        result.data.login.type == 'Organisation' &&
        result.data.login.status == 'inActive'
      ) {
        navigate('/dashboard/termsAndConditions');
      }
    },
    onError(err) {
      alert('User Not Found!');
    },

    variables: {
      email,
      password,
    },
  });

  const [forgotPassword, { loading: resetPasswordLoading }] = useMutation(
    FORGOT_PASSWORD,
    {
      update(_, result) {
        if (result) {
          alert('Please check your email and reset password.');
        }
      },
      onError(err) {
        alert('User Not Found!');
      },

      variables: {
        email,
      },
    }
  );

  function login() {
    loginUser();
  }

  function onForgotPassword() {
    if (email != '') {
      forgotPassword();
    } else {
      alert('Please provide your email address!');
    }
  }
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box
          component={motion.div}
          animate={{
            transition: {
              staggerChildren: 0.55,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
            component={motion.div}
            initial={{ opacity: 0, y: 40 }}
            animate={animate}
          >
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              type="email"
              label="Email Address"
              helperText={touched.email && errors.email}
            />
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              style={{ border: 'none' }}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? (
                        <Icon icon="eva:eye-fill" />
                      ) : (
                        <Icon icon="eva:eye-off-fill" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={animate}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    {...getFieldProps('remember')}
                    checked={values.remember}
                    style={{ color: '#0e1536' }}
                  />
                }
                label="Remember me"
              />

              <Link
                component={RouterLink}
                variant="subtitle2"
                to="#"
                underline="hover"
                style={{ color: '#0e1536' }}
                onClick={onForgotPassword}
              >
                Forgot password?
              </Link>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              onClick={login}
              style={{ backgroundColor: '#0e1536', borderRadius: 0 }}
            >
              {isSubmitting ? 'loading...' : 'Login'}
            </LoadingButton>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;
