import React, { useState, Fragment, useEffect } from 'react';
import * as Yup from 'yup';
import { Link, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import success from './success.png';
import { TextField } from '@mui/material';
import letterhead from '../../assets/images/letterhead.png';

import './Password.css';
import { UPDATE_PASSWORD } from '../../Graphql/Mutations.jsx';

const Password = (props) => {
  const [validUrl, setValidUrl] = useState(false);
  const [password, setPassword] = useState('');
  const param = useParams();

  const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD, {
    update(_, result) {
      if (result) {
        alert(result.data.updatePassword);
      }
    },
    onError(err) {
      console.log(err);
      alert('Something went wrong.');
    },

    variables: {
      id: param.id,
      password: password,
    },
  });

  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').required('Last name required'),
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: SignupSchema,
    /*  onSubmit: () => {
      setTimeout(() => {
        setAuth(true);
        navigate('/', { replace: true });
      }, 2000);
    },*/
  });

  // updatePassword();

  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        //updateToken();
        const url = `http://locatlhost:3000/api/users/${param.id}/reset/$reset/${param.token}`;
        setValidUrl(true);
      } catch (error) {
        console.log(error);
        setValidUrl(false);
      }
    };
    verifyEmailUrl();
  }, [param]);

  function onSubmit() {
    if (!param.id) {
      alert('Something went wrong!');
    }
    if (!password) {
      alert('Enter new password');
    }
    if (param.id && password) {
      updatePassword();
    }
  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <Fragment>
      {validUrl ? (
        <div className="container">
          <img
            src={letterhead}
            alt="letterhead_img"
            className="letterhead_image"
          />
          <h4
            style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 8 }}
          >
            Enter New Password
          </h4>
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            label="Password"
            helperText={touched.password && errors.password}
            style={{ marginBottom: 14 }}
          />
          <Link to="/">
            <button className="green_btn" onClick={onSubmit}>
              {' '}
              Submit{' '}
            </button>
          </Link>
        </div>
      ) : (
        <h1>404 Not Found</h1>
      )}
    </Fragment>
  );
};

export default Password;
