import images from './images';

const data = {
  user: {
    name: 'Tuatta',
    img: images.avt,
  },
  summary: [
    {
      title: 'ID/Password',
      subtitle: '01-March-2022',
      value: 'Confirmed',
      percent: 100,
      status: 'Alive',
    },
    {
      title: 'HR Certificate',
      subtitle: '12-April-2022',
      value: 'Pending',
      percent: 70,
      status: 'Alive',
    },
    {
      title: 'IT BTech',
      subtitle: '12-April-2022',
      value: 'Pending',
      percent: 70,
      status: 'Alive',
    },
    {
      title: 'Criminal Record',
      subtitle: '10-March-2022',
      value: 'Confirmed',
      percent: 100,
      status: 'Alive',
    },
  ],
  revenueSummary: {
    title: 'Revenue',
    value: '$678',
    chartData: {
      labels: ['May', 'Jun', 'July', 'Aug', 'May', 'Jun', 'July', 'Aug'],
      data: [300, 300, 280, 380, 200, 300, 280, 350],
    },
  },
  overall: [
    {
      value: 'R Nkadimeng',
      title: 'Administrator',
    },
    {
      value: 'N Miya',
      title: 'Administrator',
    },
    {
      value: '4',
      title: 'Documents',
    },
    {
      value: 'R560.00',
      title: 'Total Price',
    },
  ],
  revenueByChannel: [
    {
      title: 'Direct',
      value: 70,
    },
    {
      title: 'External search',
      value: 40,
    },
    {
      title: 'Referal',
      value: 60,
    },
    {
      title: 'Social',
      value: 30,
    },
  ],
  revenueByMonths: {
    labels: [
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'Jan',
    ],
    data: [250, 200, 300, 280, 100, 220, 310, 190, 200, 120, 250, 350],
  },
};

export default data;
