const sidebarNav = [
  {
    link: '/dashboard',
    section: 'home',
    icon: <i className="bx bx-home-alt"></i>,
    text: 'Home',
  },
  {
    link: '/dashboard/verify',
    section: 'orders',
    icon: <i className="bx bx-receipt"></i>,
    text: 'Verify Docs',
  },
  {
    link: '/dashboard/cart',
    section: 'cart',
    icon: <i className="bx bx-cart"></i>,
    text: 'Cart',
  },
  {
    link: '/dashboard/billing',
    section: 'billing',
    icon: <i className="bx bx-money"></i>,
    text: 'Billing',
  },
];

export default sidebarNav;
