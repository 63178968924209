import React, { useState, Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import success from './success.png';
import letterhead from '../../assets/images/letterhead.png';

import './Email.css';
import { UPDATE_TOKEN } from '../../Graphql/Mutations.jsx';

const Email = (props) => {
  const [validUrl, setValidUrl] = useState(false);
  const param = useParams();

  const [updateToken, { loading }] = useMutation(UPDATE_TOKEN, {
    update(_, result) {},
    onError(err) {
      console.log(err);
      alert('Something went wrong.');
    },

    variables: {
      id: param.id,
      token: param.token,
    },
  });

  // updateToken();

  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        updateToken();
        const url = `http://locatlhost:3000/api/users/${param.id}/verify/$verify/${param.token}`;
        setValidUrl(true);
      } catch (error) {
        console.log(error);
        setValidUrl(false);
      }
    };
    verifyEmailUrl();
  }, [param]);
  return (
    <Fragment>
      {validUrl ? (
        <div className="container">
          <img
            src={letterhead}
            alt="letterhead_img"
            className="letterhead_image"
          />
          <img src={success} alt="Success_img" className="success_image" />
          <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>
            Email verified successfuly
          </h4>
          <Link to="/">
            <button className="green_btn"> Login </button>
          </Link>
        </div>
      ) : (
        <h1>404 Not Found</h1>
      )}
    </Fragment>
  );
};

export default Email;
