import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import { GET_CART } from '../../Graphql/Queries';
import { DELETE_CART } from '../../Graphql/Mutations';
import { SAVE_CART } from '../../Graphql/Mutations';
import DashboardWrapper, {
  DashboardWrapperMain,
} from '../../components/dashboard-wrapper/DashboardWrapper';
import CartSummaryBox, {
  SummaryBoxSpecial,
} from '../../components/summary-box/CartSummaryBox';
import { AuthContext } from '../../context/auth';
import { data } from '../../constants';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Cart = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const id = user.id;

  const [userId, setUserId] = useState('');

  const { loading: cartLoading, data: cartData } = useQuery(GET_CART, {
    pollInterval: 2000,
    variables: {
      userId: id,
    },
  });

  var ids = [];

  if (cartData) {
    for (var i = 0; i < cartData.getCart.length; i++) {
      ids.push(cartData.getCart[i].id);
    }
  }

  const [saveCart] = useMutation(SAVE_CART, {
    update(proxy) {
      navigate('/dashboard/billing');
    },
    variables: {
      ids: ids,
    },
  });
  var checkoutButton = false;
  function checkCart() {
    if (cartData) {
      if (cartData.getCart.length > 0) {
        checkoutButton = true;
      } else {
        checkoutButton = false;
      }
    }
  }

  checkCart();

  return (
    <DashboardWrapper>
      <div>
        {checkoutButton ? (
          <Button
            variant="outlined"
            color="warning"
            onClick={saveCart}
            style={{
              marginTop: 10,
              marginRight: 'auto',
              marginBottom: 12,
              width: 110,
              color: '#34eba1',
              borderColor: '#34eba1',
              float: 'right',
            }}
          >
            Checkout
          </Button>
        ) : (
          ''
        )}
      </div>

      <DashboardWrapperMain>
        <div className="row">
          <div className="col-8 col-md-12">
            <div className="row">
              {cartLoading ? (
                <h1>Loading cart..</h1>
              ) : (
                cartData &&
                cartData.getCart.map((item, index) => (
                  <div
                    key={`summary-${index}`}
                    className="col-6 col-md-6 col-sm-12 mb"
                  >
                    <CartSummaryBox item={item} />
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="col-4 hide-md">
            <SummaryBoxSpecial item={data.revenueSummary} />
          </div>
        </div>
      </DashboardWrapperMain>
    </DashboardWrapper>
  );
};

export default Cart;
