import './assets/libs/boxicons-2.1.1/css/boxicons.min.css';
import './scss/App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import IdUpload from './pages/idUpload/IdUpload';
import Cart from './pages/cart/Cart';
import TermsAndConditions from './pages/termsAndConditions/TermsAndConditions';
import Email from './pages/email/Email';
import Password from './pages/password/Password';
import Billing from './pages/billing/Billing';
import Home from './pages/home/Home';
import Dashboard from './pages/Dashboard';
import Verify from './pages/verify/Verify';
import MainLayout from './layout/MainLayout';
import { AuthProvider, AuthContext } from './context/auth';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/users/:id/verify/:token" element={<Email />} />
          <Route path="/users/:id/reset/:token" element={<Password />} />
          <Route path="/dashboard" element={<MainLayout />}>
            <Route index element={<Dashboard />} />
            <Route
              path="/dashboard/termsAndConditions"
              element={<TermsAndConditions />}
            />
            <Route path="/dashboard/cart" element={<Cart />} />
            <Route path="/dashboard/idUpload" element={<IdUpload />} />
            <Route path="/dashboard/verify" element={<Verify />} />
            <Route path="/dashboard/billing" element={<Billing />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
