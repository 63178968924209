import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { default as MainBox } from '../../components/box/Box';
import DashboardWrapper, {
  DashboardWrapperMain,
  DashboardWrapperRight,
} from '../../components/dashboard-wrapper/DashboardWrapper';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { createClient } from '@supabase/supabase-js';
import { Container, Link, Divider, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import Box from '@mui/material/Box';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import NoteIcon from '@mui/icons-material/Note';
import ArticleIcon from '@mui/icons-material/Article';
import FeedIcon from '@mui/icons-material/Feed';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import HouseIcon from '@mui/icons-material/House';
import PublicIcon from '@mui/icons-material/Public';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import CarRentalIcon from '@mui/icons-material/CarRental';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { motion } from 'framer-motion';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useMutation, useQuery, gql } from '@apollo/client';
import { CREATE_VERIFICATION } from '../../Graphql/Mutations';

import './Verify.scss';
import { AuthContext } from '../../context/auth';

//////////////////////////////////
const RootStyle = styled('div')({
  background: 'rgb(249, 250, 251)',
  height: '100vh',
  display: 'grid',
  placeItems: 'center',
});

const HeadingStyle = styled(Box)({
  textAlign: 'center',
});

const ContentStyle = styled('div')({
  maxWidth: 480,
  padding: 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fff',
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Verify = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [maidenName, setMaidenName] = useState('N/A');
  const [idNumber, setIdNumber] = useState('');
  const [qualification, setQualification] = useState('');
  const [institution, setInstitution] = useState('');
  const [year, setYear] = useState('');
  const [number, setNumber] = useState();
  const [reason, setReason] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [code, setCode] = useState('');
  const [prdp, setPrdp] = useState('');
  const [currentEmployer, setCurrentEmployer] = useState('');
  const [refereeName, setRefereeName] = useState('');
  const [refereeNumber, setRefereeNumber] = useState('');
  const [saved, setSaved] = useState(false);
  const [fileSelect, setFileSelect] = useState('');
  const [verificationType, setVerificationType] = useState('');
  const [price, setPrice] = useState(0);
  const [agreement, setAgreement] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { user } = useContext(AuthContext);
  const id = user.id;

  const navigate = useNavigate();

  const SUPABASE_KEY =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxhaG9raXlxbnZjYWdwY2R6dmN4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDQ2MDI5OTEsImV4cCI6MTk2MDE3ODk5MX0.-OBafJvE4CB6IGMNV3qesbrWna2oMoPPhb1Cxqq65Ao';
  const SUPABASE_URL = 'https://lahokiyqnvcagpcdzvcx.supabase.co';

  const [file, setFile] = useState('');

  const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

  const [createVerification, { loading }] = useMutation(CREATE_VERIFICATION);

  let easing = [0.6, -0.05, 0.01, 0.99];
  const fadeInUp = {
    initial: {
      y: 60,
      opacity: 0,
      transition: { duration: 0.6, ease: easing },
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: easing,
      },
    },
  };

  const Input = styled('input')({
    display: 'none',
  });

  const animate = {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: easing,
      delay: 0.16,
    },
  };

  function addVerification(type, price) {
    setVerificationType(type);
    setPrice(price);
    handleOpen();
  }

  const onAdd = async () => {
    var fileName = '';

    if (file != '') {
      let arr = file.name.split('.');
      let extension = arr.pop();

      fileName =
        'verification_picture_name_' +
        Math.floor(Math.random() * 10000000000 + 2) +
        '.' +
        extension;
      //console.log(file);
      const { data, error } = await supabase.storage
        .from('motorluv-bucket/verifications')
        .upload(fileName, file);
      if (error) {
        console.log(error);
      } else {
        // console.log(data);
      }
    } else {
      fileName = '';
    }

    if (user.type == 'Organisation') {
      if (!agreement) {
        alert('Please read and agree to our disclaimer!');
      } else {
        if (
          firstName != '' &&
          lastName != '' &&
          idNumber != '' &&
          maidenName != '' &&
          reason != ''
        ) {
          createVerification({
            update(_, result) {
              alert('Request added to cart!');
            },
            variables: {
              verificationType: verificationType,
              user: user.id,
              fileName: fileName,
              firstName: firstName,
              lastName: lastName,
              idNumber: idNumber,
              maidenName: maidenName,
              qualification: qualification,
              institution: institution,
              year: year,
              requestReason: reason,
              licenseNumber: licenseNumber,
              code: code,
              price: price,
              prdp: prdp,
              currentEmployer: currentEmployer,
              refereeName: refereeName,
              refereeNumber: refereeNumber,
              saved: saved,
            },
          });

          setQualification('');
          setInstitution('');
          setYear('');
          setLicenseNumber('');
          setCode('');
          setPrdp('');
          setCurrentEmployer('');
          setRefereeName('');
          setRefereeNumber('');
        } else {
          alert('Please fill in all candidate fields!');
        }
      }
    } else {
      if (reason != '') {
        createVerification({
          update(_, result) {
            alert('Request added to cart!');
          },
          variables: {
            verificationType: verificationType,
            user: id,
            fileName: fileName,
            firstName: user.firstName,
            lastName: user.lastName,
            idNumber: user.idNumber,
            maidenName: user.maidenName,
            qualification: qualification,
            institution: institution,
            year: year,
            requestReason: reason,
            licenseNumber: licenseNumber,
            code: code,
            price: price,
            prdp: prdp,
            currentEmployer: currentEmployer,
            refereeName: refereeName,
            refereeNumber: refereeNumber,
            saved: saved,
          },
        });
      } else {
        alert('Please select the reason!');
      }
    }

    handleClose();
  };

  var filePermission = true;
  if (
    verificationType == 'Citizenship' ||
    verificationType == 'Directorship' ||
    verificationType == 'Work Permit' ||
    verificationType == 'Permanent Residence' ||
    verificationType == 'Institution Accreditation' ||
    verificationType == 'Vehicle Ownership History' ||
    verificationType == 'Bank Account Verification' ||
    verificationType == 'TPN Enquiry' ||
    verificationType == 'PSIRA Registration' ||
    verificationType == 'Deeds Search' ||
    verificationType == 'Reference Check' ||
    verificationType == 'Employment Confirmation' ||
    verificationType == 'Criminal Record' ||
    verificationType == 'Fraudlisting' ||
    verificationType == 'Credit Check'
  ) {
    filePermission = false;
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <RootStyle>
          <Container maxWidth="sm">
            <ContentStyle>
              <Typography
                sx={{ color: 'text.secondary', mb: 5 }}
                style={{ textAlign: 'center', fontWeight: 'bold' }}
              >
                Pick your document below
              </Typography>

              <Divider sx={{ my: 3 }} component={motion.div} {...fadeInUp}>
                <Typography variant="body2" sx={{ color: 'green' }}>
                  {verificationType}
                </Typography>
              </Divider>

              <Typography
                component={motion.p}
                {...fadeInUp}
                variant="body2"
                align="center"
                sx={{ mt: 3 }}
              >
                {verificationType == 'Employment Confirmation' ? (
                  <div style={{ margin: 12 }}>
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Current Employer"
                      onChange={(e) => setCurrentEmployer(e.target.value)}
                      className="user-details-input"
                    />

                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Name Of Referee"
                      onChange={(e) => setRefereeName(e.target.value)}
                      className="user-details-input"
                    />

                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Referee Number"
                      onChange={(e) => setRefereeNumber(e.target.value)}
                      className="user-details-input"
                    />
                  </div>
                ) : (
                  ''
                )}
                {filePermission ? (
                  <>
                    {verificationType == 'Drivers License' ? (
                      <div style={{ margin: 12 }}>
                        <TextField
                          variant="standard"
                          fullWidth
                          type="text"
                          label="Diver's License Number"
                          onChange={(e) => setLicenseNumber(e.target.value)}
                          className="user-details-input"
                        />

                        <TextField
                          variant="standard"
                          fullWidth
                          type="text"
                          label="Code (E.g B/EB ETC)"
                          onChange={(e) => setCode(e.target.value)}
                          className="user-details-input"
                        />

                        <TextField
                          variant="standard"
                          fullWidth
                          type="text"
                          label="PRDP"
                          onChange={(e) => setPrdp(e.target.value)}
                          className="user-details-input"
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {verificationType != 'Drivers License' ? (
                      <div style={{ margin: 12 }}>
                        <TextField
                          variant="standard"
                          fullWidth
                          type="text"
                          label="Qualification Name"
                          onChange={(e) => setQualification(e.target.value)}
                          className="user-details-input"
                        />

                        <TextField
                          variant="standard"
                          fullWidth
                          type="text"
                          label="Institution"
                          onChange={(e) => setInstitution(e.target.value)}
                          className="user-details-input"
                        />

                        <TextField
                          variant="standard"
                          fullWidth
                          type="text"
                          label="Year Of Completion"
                          onChange={(e) => setYear(e.target.value)}
                          className="user-details-input"
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    <Stack
                      component={motion.div}
                      initial={{ opacity: 0, y: 60 }}
                      animate={animate}
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={2}
                    >
                      <label
                        htmlFor="contained-button-file"
                        style={{ marginTop: 12 }}
                      >
                        <Input
                          accept="image/*"
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                            setFileSelect('File Selected');
                          }}
                        />
                        <Button variant="outlined" component="span">
                          {fileSelect ? fileSelect : 'Select Document'}
                        </Button>
                      </label>
                    </Stack>
                  </>
                ) : (
                  ''
                )}
                <Button
                  fullWidth
                  variant="outlined"
                  color="warning"
                  onClick={onAdd}
                  style={{
                    marginTop: 10,
                    marginRight: 'auto',
                    marginBottom: 12,
                  }}
                >
                  Add
                </Button>
                <Link
                  variant="subtitle2"
                  style={{ cursor: 'pointer' }}
                  onClick={handleClose}
                >
                  Cancel
                </Link>
              </Typography>
            </ContentStyle>
          </Container>
        </RootStyle>
      </Modal>
      <div className="row">
        <div className="col- col-md-12 col-sm-12 mb">
          <Button
            startIcon={<ShoppingCartIcon color="#eb9e34" />}
            variant="outlined"
            color="warning"
            onClick={(e) => navigate('/dashboard/cart')}
            style={{
              marginTop: 10,
              marginRight: 'auto',
              marginBottom: 12,
              width: 110,
              color: '#34eba1',
              borderColor: '#34eba1',
              float: 'right',
            }}
          >
            CART
          </Button>
        </div>
      </div>

      <div className="row">
        <div className="col- col-md-12 col-sm-12 mb">
          <MainBox>
            {user.type == 'Organisation' ? (
              <div className="row">
                <div className="col- col-md-12 col-sm-12 mb">
                  <h5 style={{ textAlign: 'center', marginBottom: 14 }}>
                    Candidate Personal Details
                  </h5>
                  <div className="summary-box">
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="ID Numbers"
                      onChange={(e) => setIdNumber(e.target.value)}
                      className="user-details-input"
                    />
                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Frist Name"
                      onChange={(e) => setFirstName(e.target.value)}
                      className="user-details-input"
                    />

                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Last Name"
                      onChange={(e) => setLastName(e.target.value)}
                      className="user-details-input"
                    />

                    <TextField
                      variant="standard"
                      fullWidth
                      type="text"
                      label="Maiden Name"
                      onChange={(e) => setMaidenName(e.target.value)}
                      className="user-details-input"
                    />
                    <TextField
                      fullWidth
                      variant="standard"
                      id="outlined-select-currency-native"
                      select
                      label="Reason"
                      SelectProps={{
                        native: true,
                      }}
                      style={{ border: 'none' }}
                      onChange={(e) => setReason(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="Employment Purpose">
                        Employment Purpose
                      </option>
                      <option value="Fraud Prevention & Detection">
                        Fraud Prevention & Detection
                      </option>
                    </TextField>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col- col-md-12 col-sm-12 mb">
                <>
                  <List
                    sx={{
                      bgcolor: 'background.paper',
                    }}
                    className="disclainerContainer"
                  >
                    {user.type == 'Organisation' ? (
                      <>
                        <h6 style={{ textAlign: 'center' }}>
                          The undersigned hereby confirm that the candidate/
                          data subject has read the terms of the{' '}
                          <a
                            style={{ color: '#1d94cc' }}
                            href="https://lahokiyqnvcagpcdzvcx.supabase.co/storage/v1/object/public/motorluv-bucket/mdt/TERMS%20AND%20CONDITIONS.pdf"
                          >
                            consent form
                          </a>{' '}
                          and voluntarily give permission that:
                        </h6>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <DownloadDoneIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText secondary="MDAR Media t/a MDT Institute and its representatives may forward any of their personal information and any information that I have provided to the information partners for verification purposes and reports." />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <DownloadDoneIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText secondary="Information partners may provide MDAR Media t/a MDT Institute with reports to support their application for employment/ or purpose" />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <DownloadDoneIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText secondary="Their personal information is confidential and will be solely used for purposes of current employment/ potential/ future employment prospects or any purpose stipulated herein. I further give permission to MDAR Media t/a MDT Institute to disperse reports obtained using my personal information to my employer or potential employer or any agency that has obtained my consent in this regard." />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <DownloadDoneIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText secondary="The information provided is true to the best of their knowledge and can be contacted to verify or update my details when necessary." />
                        </ListItem>
                      </>
                    ) : (
                      <>
                        <h6 style={{ textAlign: 'center' }}>
                          I hereby{' '}
                          <a
                            style={{ color: '#1d94cc' }}
                            href="https://lahokiyqnvcagpcdzvcx.supabase.co/storage/v1/object/public/motorluv-bucket/mdt/TEAND CONDITIONS.pdf"
                          >
                            consent
                          </a>{' '}
                          voluntarily and give permission that:
                        </h6>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <DownloadDoneIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText secondary="MDAR Media t/a MDT Institute and its representatives may forward any of my personal information and any information that I have provided to the information partners for verification purposes and reports." />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <DownloadDoneIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText secondary="Information partners may provide MDAR Media t/a MDT Institute with reports to support my application for employment." />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <DownloadDoneIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText secondary="My personal information is confidential and will be solely used for purposes of current employment/ potential/ or any future employment prospects. I further give permission to MDAR Media t/a MDT Institute to disperse reports obtained using my personal information to my employer or potential employer." />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <DownloadDoneIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText secondary="The information provided is true to the best of my knowledge and I can be contacted to verify or update my details when necessary." />
                        </ListItem>

                        <TextField
                          variant="standard"
                          fullWidth
                          labelId="demo-simple-select-standard-label"
                          select
                          label="Reason"
                          SelectProps={{
                            native: true,
                          }}
                          style={{ border: 'none' }}
                          onChange={(e) => setReason(e.target.value)}
                        >
                          <option value=""></option>
                          <option value="Employment Purpose">
                            Employment Purpose
                          </option>
                          <option value="Fraud Prevention & Detection">
                            Fraud Prevention & Detection
                          </option>
                        </TextField>
                      </>
                    )}

                    <FormControlLabel
                      control={
                        <Checkbox onChange={() => setAgreement(!agreement)} />
                      }
                      label="Agree"
                    />
                  </List>
                </>
              </div>
            </div>
          </MainBox>
        </div>
      </div>

      <DashboardWrapper>
        <DashboardWrapperMain>
          <div className="row">
            <div className="col-8 col-md-12">
              <div className="row">
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Criminal Record</div>
                          <span>R395.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Criminal Record', 395.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <FingerprintIcon
                          sx={{ fontSize: 100, color: '#a9050d' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Fraudlisting</div>
                          <span>R125.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Fraudlisting', 125.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <LaptopMacIcon
                          sx={{ fontSize: 100, color: '#08163c' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Employment Confirmation</div>
                          <span>R210.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Employment Confirmation', 210.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <BusinessCenterOutlinedIcon
                          sx={{ fontSize: 100, color: '#d1bc06' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Credit Check</div>
                          <span>R100.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Credit Check', 100.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <CreditCardOutlinedIcon
                          sx={{ fontSize: 100, color: '#52b4d0' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Reference Check</div>
                          <span>R810.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Reference Check', 810.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <GroupsOutlinedIcon
                          sx={{ fontSize: 100, color: '#52d196' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>SA ID</div>
                          <span>R80.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) => addVerification('SA ID', 80.0)}
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <AccountBoxOutlinedIcon
                          sx={{ fontSize: 100, color: '#c652d0' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Higher Education Qualification</div>
                          <span>R398.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification(
                                'Higher Education Qualification',
                                398.0
                              )
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <DocumentScannerIcon
                          sx={{ fontSize: 100, color: '#fcb603' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Matric/Senior Certificate (Post 1992)</div>
                          <span>R150.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification(
                                'Matric/Senior Certificate (Post 1992)',
                                150.0
                              )
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <NoteIcon
                          sx={{ fontSize: 100, color: '#cfccc6' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Senior Certificate (Pre 1992)</div>
                          <span>R260.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification(
                                'Senior Certificate (Pre 1992)',
                                260.0
                              )
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <NoteIcon
                          sx={{ fontSize: 100, color: '#cff547' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>

                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Trade Qualifications (QCTO)</div>
                          <span>R85.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification(
                                'Trade Qualifications (QCTO)',
                                85.0
                              )
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <ArticleIcon
                          sx={{ fontSize: 100, color: '#7826c9' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Foreign Qualification</div>
                          <span>R85.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Foreign Qualification', 85.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <FeedIcon
                          sx={{ fontSize: 100, color: '#c92456' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>
                            Verification of SAQA Certificate of Evaluation
                          </div>
                          <span>R470.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification(
                                'Verification of SAQA Certificate of Evaluation',
                                470.0
                              )
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <FilePresentIcon
                          sx={{ fontSize: 100, color: '#3a24c9' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>

                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Directorship</div>
                          <span>R22.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Directorship', 22.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <EventSeatIcon
                          sx={{ fontSize: 100, color: '#07242b' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>

                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Deeds Search</div>
                          <span>R40.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Deeds Search', 40.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <HouseIcon
                          sx={{ fontSize: 100, color: '#0c5e46' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>

                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Citizenship</div>
                          <span>R110.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Citizenship', 110.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <PublicIcon
                          sx={{ fontSize: 100, color: '#0f5f75' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>

                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Passport</div>
                          <span>R136.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) => addVerification('Passport', 136.0)}
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <CreditScoreIcon
                          sx={{ fontSize: 100, color: '#e1e807' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>

                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Work Permit</div>
                          <span>R510.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Work Permit', 510.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <ReceiptIcon
                          sx={{ fontSize: 100, color: '#6c07e8' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>

                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Permanent Residence</div>
                          <span>R265.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Permanent Residence', 265.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <VpnLockIcon
                          sx={{ fontSize: 100, color: '#e87b07' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>

                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Drivers License</div>
                          <span>R155.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Drivers License', 155.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <DirectionsCarIcon
                          sx={{ fontSize: 100, color: '#0774e8' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>

                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Professional Drivers Permit (PDP)</div>
                          <span>R145.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification(
                                'Professional Drivers Permit (PDP)',
                                145.0
                              )
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <ElectricCarIcon
                          sx={{ fontSize: 100, color: '#07e8b0' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>

                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Institution Accreditation</div>
                          <span>R270.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification(
                                'Institution Accreditation',
                                270.0
                              )
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <CorporateFareIcon
                          sx={{ fontSize: 100, color: '#e8ac07' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Registered Qualification</div>
                          <span>R270.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Registered Qualification', 270.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <FormatListNumberedIcon
                          sx={{ fontSize: 100, color: '#e8076c' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Professional Membership</div>
                          <span>R130.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Professional Membership', 130.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <DynamicFormIcon
                          sx={{ fontSize: 100, color: '#07b7e8' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>

                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Vehicle Ownership History</div>
                          <span>R248.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification(
                                'Vehicle Ownership History',
                                248.0
                              )
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <CarRentalIcon
                          sx={{ fontSize: 100, color: '#e8071d' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>

                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>Bank Account Verification</div>
                          <span>R25.90</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('Bank Account Verification', 25.9)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <NoAccountsIcon
                          sx={{ fontSize: 100, color: '#0e9990' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>

                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>TPN Enquiry</div>
                          <span>R60.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('TPN Enquiry', 60.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <ContactPhoneIcon
                          sx={{ fontSize: 100, color: '#0e7299' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>

                <div className="col-6 col-md-6 col-sm-12 mb">
                  <MainBox>
                    <div className="summary-box">
                      <div className="summary-box__info">
                        <div className="summary-box__info__title">
                          <div>PSIRA Registration</div>
                          <span>R163.00</span>
                        </div>
                        <div className="summary-box__info__value">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddShoppingCartIcon color="#ffffff" />}
                            onClick={(e) =>
                              addVerification('PSIRA Registration', 163.0)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div className="summary-box__chart">
                        <WorkspacePremiumIcon
                          sx={{ fontSize: 100, color: '#656f73' }}
                          className="clocks-icons"
                        />
                      </div>
                    </div>
                  </MainBox>
                </div>
              </div>
            </div>
          </div>
        </DashboardWrapperMain>
      </DashboardWrapper>
    </>
  );
};

export default Verify;
