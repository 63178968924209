import React, { useContext } from 'react';
import './topnav.scss';
import UserInfo from '../user-info/UserInfo';
import { AuthContext } from '../../context/auth';

import { data } from '../../constants';

const TopNav = () => {
  const { user } = useContext(AuthContext);
  const openSidebar = () => {
    document.body.classList.add('sidebar-open');
  };

  return (
    <div className="topnav">
      <UserInfo user={user} />
      {user.status == 'Active' ? (
        <div className="sidebar-toggle" onClick={openSidebar}>
          <i className="bx bx-menu-alt-right"></i>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default TopNav;
