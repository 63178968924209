import * as Yup from 'yup';
import { useState, useContext } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  Box,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { createClient } from '@supabase/supabase-js';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import { useMutation } from '@apollo/react-hooks';
import { AuthContext } from '../context/auth';
import { CREATE_USER } from '../Graphql/Mutations.jsx';
import '../pages/signup/Signup.css';
import LoginForm from './LoginForm';

/////////////////////////////////////////////////////////////
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const SignupForm = (props) => {
  const SUPABASE_KEY =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxhaG9raXlxbnZjYWdwY2R6dmN4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDQ2MDI5OTEsImV4cCI6MTk2MDE3ODk5MX0.-OBafJvE4CB6IGMNV3qesbrWna2oMoPPhb1Cxqq65Ao';
  const SUPABASE_URL = 'https://lahokiyqnvcagpcdzvcx.supabase.co';

  const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);
  const context = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [maidenName, setMaidenName] = useState('N/A');
  const [organisationName, setOrganisationName] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [type, setType] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [status, setStatus] = useState('inActive');
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [createUser, { loading }] = useMutation(CREATE_USER, {
    update(_, result) {
      if (result) {
        alert('Please verify your email and Login.');
      }
    },
    onError(err) {
      alert(err.message);
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onSubmit = async () => {
    if (validateEmail(email)) {
      if (
        firstName != '' &&
        maidenName != '' &&
        lastName != '' &&
        idNumber != '' &&
        email != '' &&
        password != '' &&
        passwordRepeat != '' &&
        type != '' &&
        status != ''
      ) {
        if (password == passwordRepeat) {
          if (type == 'Organisation') {
            createUser({
              variables: {
                firstName,
                maidenName,
                lastName,
                idNumber,
                email,
                password,
                type,
                status,
                registrationNumber,
                fileName: '',
                organisationName,
              },
            });
          }
          if (type == 'Individual') {
            createUser({
              variables: {
                firstName,
                maidenName,
                lastName,
                idNumber,
                email,
                password,
                type,
                status,
                fileName: '',
                organisationName,
              },
            });

            setSuccessMessage('Succssfully signed up!');
          }
        } else {
          setErrorMessage('You must repeat the same password');
        }
      } else {
        setErrorMessage('Please fill in all fields.');
      }
    } else {
      alert('Enter a valid email address!');
    }
  };

  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').required('Last name required'),
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: SignupSchema,
    /*  onSubmit: () => {
      setTimeout(() => {
        setAuth(true);
        navigate('/', { replace: true });
      }, 2000);
    },*/
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  function onChange(value) {
    setIsDisabled(false);
  }
  return (
    <div className="signup-div">
      {errorMessage ? (
        <p
          style={{
            color: 'red',
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: 14,
          }}
        >
          {errorMessage}
        </p>
      ) : (
        ''
      )}
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack
              component={motion.div}
              initial={{ opacity: 0, y: 60 }}
              animate={animate}
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
            >
              <TextField
                fullWidth
                type="text"
                label="First name"
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                fullWidth
                type="text"
                label="Maiden name"
                onChange={(e) => setMaidenName(e.target.value)}
              />

              <TextField
                fullWidth
                type="text"
                label="Last name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </Stack>

            <TextField
              fullWidth
              type="text"
              label="ID Number"
              onChange={(e) => setIdNumber(e.target.value)}
            />

            <Stack
              spacing={3}
              component={motion.div}
              initial={{ opacity: 0, y: 40 }}
              animate={animate}
            >
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Email address"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Stack
                component={motion.div}
                initial={{ opacity: 0, y: 60 }}
                animate={animate}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
              >
                <TextField
                  fullWidth
                  id="outlined-select-currency-native"
                  select
                  label="User Type"
                  SelectProps={{
                    native: true,
                  }}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="">...</option>
                  <option value="Organisation">Organisation</option>
                  <option value="Individual">Individual</option>
                </TextField>
              </Stack>

              {type == 'Organisation' ? (
                <>
                  <TextField
                    fullWidth
                    type="text"
                    label="Company Registration Number"
                    onChange={(e) => setRegistrationNumber(e.target.value)}
                  />
                  <TextField
                    fullWidth
                    autoComplete="organisation"
                    type="text"
                    label="Organisation Name"
                    onChange={(e) => setOrganisationName(e.target.value)}
                  />
                </>
              ) : (
                ''
              )}

              <TextField
                fullWidth
                autoComplete="current-password"
                type={password ? 'text' : 'password'}
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <Icon
                          icon={
                            showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={touched.password && errors.password}
              />
              <TextField
                fullWidth
                autoComplete="current-password"
                type={password ? 'text' : 'passwordReapeate'}
                label="Reapeat Password"
                onChange={(e) => setPasswordRepeat(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <Icon
                          icon={
                            showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={touched.passwordRepeat && errors.passwordRepeat}
              />
            </Stack>
            <ReCAPTCHA
              sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              onChange={onChange}
            />

            <Box
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={animate}
            >
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={onSubmit}
                disabled={isDisabled}
              >
                Sign up
              </LoadingButton>
            </Box>
          </Stack>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default SignupForm;
