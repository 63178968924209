import React from 'react';
import './summary-box.scss';
import Box from '../box/Box';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { colors } from '../../constants';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SummaryBox = ({ item }) => {
  var color = 'red';
  if (item.status == 'Inconclusive') {
    color = 'grey';
  }

  if (item.status == 'Pending') {
    color = '#ff7d1a';
  }

  if (
    item.status == 'Confirmed-No' ||
    item.status == 'Closed' ||
    item.status == 'Expired'
  ) {
    color = '#fc0307';
  }

  if (item.status == 'Confirmed-Yes' || item.status == 'Valid') {
    color = '#1dd186';
  }

  let randFormat = Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
  });

  return (
    <Box>
      <div className="summary-box">
        <div className="summary-box__info">
          <div className="summary-box__info__title">
            <div>{item.verificationType}</div>
            <span>{item.createdAt}</span>
            <p style={{ color: '#0362fc' }}>{randFormat.format(item.price)}</p>
          </div>
          <div className="summary-box__info__value">{item.status}</div>
        </div>
        <div className="summary-box__chart">
          <CircularProgressbarWithChildren
            value={item.status == 'Pending' ? 70 : 100}
            strokeWidth={10}
            styles={buildStyles({
              pathColor: color,
              trailColor: 'transparent',
              strokeLinecap: 'round',
            })}
          >
            <div className="summary-box__chart__value">{item.status}</div>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    </Box>
  );
};

export default SummaryBox;

export const SummaryBoxSpecial = ({ item }) => {
  const chartOptions = {
    responsive: true,
    scales: {
      xAxis: {
        display: false,
      },
      yAxis: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const chartData = {
    labels: item.chartData.labels,
    datasets: [
      {
        label: 'Revenue',
        data: item.chartData.data,
        borderColor: '#fff',
        tension: 0.5,
      },
    ],
  };
  return (
    <Box purple fullheight>
      <div className="summary-box-special">
        <div className="summary-box-special__title">{item.title}</div>
        <div className="summary-box-special__value">{item.value}</div>
        <div className="summary-box-special__chart">
          <Line options={chartOptions} data={chartData} width={`250px`} />
        </div>
      </div>
    </Box>
  );
};
